@import '../../global-components/brand-assets/bw-styles.scss';

.product {
  height: 100%;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;

  .product-container {
    position: relative;
    height: 100%;
    display: flex;
    gap: $basic-increment * 4;

    .product-model-preview-container {
      position: relative;
      flex: 1.5;
      gap: $basic-increment * 4;
      overflow: hidden;
      
      .stl-viewer {
        z-index: 1;
      }

      .viewer-vignette {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        pointer-events: none;
        background: linear-gradient(to right, transparent 70%, $bw-background-grey 100%);
      }

      .viewer-nav-container {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        pointer-events: none;
        .viewer-nav-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          cursor: pointer;
          pointer-events: all;
          transition: all 140ms $default-easing;
          &:hover {
            opacity: 1;
            transform: scale(1.15);
          }
        }
      }

      .viewer-file-indicator-container {
        position: absolute;
        z-index: 3;
        bottom: $basic-increment * 3 - 3;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .viewer-file-indicator-item {
          padding: $basic-increment / 2;
          cursor: pointer;
          opacity: 0.3;
          &.active {
            opacity: 1; 
            cursor: default;
          }
          &:not(.active):hover {
            opacity: 0.6;
          }

          .viewer-file-indicator-inner-circle {
            position: relative;
            width: 11px;
            height: 11px;
            border: 2px solid $bw-background-grey;
            border-radius: 10px;
            background-color: $bw-green;

            &.gcode {
              border-radius: 3px;
            }
          }
        }
      }

      .stl-viewer-container {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;

        .viewer {
          position: relative;
          flex: 1 0 100%;
          width: 100%;
          height: 100%;
          transition: transform 400ms $default-easing;

          .file-info {
            position: absolute;
            z-index: 10;
            left: $basic-increment * 3;
            bottom: $basic-increment * 3;
          }
        }
      }


    }
    .product-details-container {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: $basic-increment * 2;
      max-height: 100%;
      padding-top: $basic-increment * 3;
      padding-right: $basic-increment*3;
      
      .product-details-header {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        .product-title {
          line-height: 1em;
        }
        .button-group.horizontal {
          position: relative;
          display:flex;
          gap: $basic-increment;
        }
      }

      .product-details-scroll-container {
        position: relative;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        gap: $basic-increment * 2;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        padding-bottom: $basic-increment * 10;
        
        .collapsible-trigger {
          cursor: pointer;
          &:hover {
            h4 {
            
            }
          }
        }
      }
    }
    .product-actions {
      position: fixed;
      right: $basic-increment * 3;
      bottom: $basic-increment * 3;
    }
  }
}