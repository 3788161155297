@import '../../global-components/brand-assets/bw-styles.scss';

.submit-product-card {
  border-radius: $basic-increment * 2;
  transition: all 160ms $default-easing;
  cursor: pointer;
  aspect-ratio: 1/1;
  border: 1px solid rgba($bw-green, 0.2);

  .icon {
    transition: all 430ms $default-easing 50ms;
    transform: scale(1);
  }
  .text {
    transition: all 130ms $default-easing 50ms;
    opacity: 0;
  }

  &:hover {
    border: 1px solid rgba($bw-green, 0.5);
    background: rgba($bw-pale-sage, 0.4);
    text-shadow: 0px 0px 80px rgba(0, 0, 0, 0.05);
    .icon {
      transform: scale(1.2);
    }
    .text {
      opacity: 1;
    }
  }
}