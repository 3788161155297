@import './global-components/brand-assets/bw-styles.scss';

.dw {
  position:relative;
  height: 100vh;
  width: 100vw;
  // padding-top: $basic-increment * 5;

  .view {
    padding: $basic-increment*3 $basic-increment*3 0 $basic-increment*3;
  }
}