$bw-green: #36463D;
$bw-pale-sage: #E7EBE6;
$bw-pale-pink: #E9E1EA;
$bw-pale-violet: #E6E6FD;
$bw-highlight: #D3B549;
$bw-background-grey: #f5f5f5;
$bw-background-grey-lighter: #F3F4F3;
$bw-grey: #d9d9d9;
$bw-grey-hover: #c3c3c3;
$bw-grey-focus: #868686;
$bw-super-light-grey: #f0f0f0;
$bw-ultra-light-grey: #FAFAFA;
$bw-dark-green-tinted: #212723;

$bw-green-30-percent: #C4C8C5;


/* UI */
$ui-button-on-green-fill: #414E44;
$ui-button-on-bw-green-stroke: #4C5C50;
$ui-info-purple: #8983D1;
$ui-info-purple-brighter: #AAA5E1;
$ui-info-purple-super-bright: #E7E5FA;
$ui-confirmation-green: #62B476;

