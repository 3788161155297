@import '@/global-components/brand-assets/bw-styles.scss';

.filament {
  position: relative;
  width: 45px;
  height: 45px;

  &.small {
    width: 10px;
    height: 10px;
    .filament-ring {
      border-width: 1px;
    }
  }

  &.med {
    width: 18px;
    height: 18px;
  }

  .filament-ring {
    position: absolute;
    border-radius: 100px;
    border: 2px solid white;
    opacity: $before-hover-opacity;
    background: none;


    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.active {
      opacity: 1;
    }
  }
} 