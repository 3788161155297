@import '../../brand-assets/bw-styles.scss';

.pulsate {
  animation: pulsate 2s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 0px rgba($ui-confirmation-green, 0.2);
  }
  50% {
    box-shadow: 0 0 50px rgba($ui-confirmation-green, 0.2);
  }
  100% {
    box-shadow: 0 0 0px rgba($ui-confirmation-green, 0.2);
  }
}