@import '../../global-components/brand-assets/bw-styles.scss';

.quick-print {
  pointer-events: none;
  opacity: 0;
  box-shadow: inset 0 0 150px rgba($bw-highlight, 0.3);
  transition: all 140ms $default-easing;
  z-index: 50;

  &.hovering-file {
    opacity: 1;
    z-index: 50;
  }
}