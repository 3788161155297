@keyframes moveAndReturn {
  0% {
    left: 0;
    top: 0;
    transform: rotateZ(0deg);
  }
  50% {
    left: -200%;
    top: -100%;
    transform: rotateZ(-45deg);
  }
  100% {
    left: 0;
    top: 0;
    transform: rotateZ(0deg);
  }
}

.moveforthandback {
  width: 300%;
  height: 300%;
  animation: moveAndReturn 640s linear infinite;
}