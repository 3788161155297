@import '../../global-components/brand-assets/bw-styles.scss';

.navbar {
  position: fixed;
  z-index: 20;
  top: 0;
  height: $basic-increment * 5;
  padding: 0 $basic-increment 0 $basic-increment*2;
  width:100%;
  font-size: $small-type;

  color: $bw-pale-sage;

  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    gap: $basic-increment * 2;
    button {
      color: $bw-pale-sage;
      transition: all 160ms $default-easing;
    }
    .seperator {
      height: $basic-increment;
      width: 1px;
      background-color: $bw-pale-sage;
      opacity: $before-hover-opacity;
    }
    .search-input-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: end;
      align-items: center;
      .icon {
        opacity: 0.3;
        transition: all 160ms $default-easing;
      }

      input:active + .icon {
        opacity: 1;
        transform: scale(1.1);
      }
      input:focus + .icon {
        opacity: 1;
        transform: scale(1.1);
      }


      &:hover {
        .icon {
          opacity: 1;
          transform: scale(1.1);
        }
      }
    }
  }
}