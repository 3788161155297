@import '../../global-components/brand-assets/bw-styles.scss';

.popover-content {
  width: 550px;
}

.messages {
  .message {
    min-width: 35%;
    max-width: 80%;
    .message-content {
      border-radius: $basic-increment * 2.5;
      padding: $basic-increment*1.2 $basic-increment*2 $basic-increment*1.2 $basic-increment*2;
    }
  }
}